import type { RouteLocationNormalized } from 'vue-router'
import { ROUTE_NAMES } from '~/constants'
import type { Device } from '~/types/models'

interface RedirectUrl {
  url: string
  external: boolean
}

interface PossibleParams {
  device?: Device | null
}

export async function getRedirectUrl(to: RouteLocationNormalized, params: PossibleParams): Promise<RedirectUrl | null> {
  const redirectPaymentUrl = await redirectToPaymentIfPossible(to)
  if (redirectPaymentUrl) return { url: redirectPaymentUrl, external: true }

  const redirectSigninUrl = await redirectIfSigningLinkFound(to)
  if (redirectSigninUrl) return { url: redirectSigninUrl, external: true }

  const redirectInstallmentSigninUrl = await redirectIfInstallmentSigningLinkFound(to)
  if (redirectInstallmentSigninUrl) return { url: redirectInstallmentSigninUrl, external: true }

  const shortLinkUrl = await redirectIfShortLinkFound(to)
  if (shortLinkUrl) return { url: shortLinkUrl, external: true }

  const fileUrl = redirectToFileIfPossible(to)
  if (fileUrl) return { url: fileUrl, external: true }

  const shortLinkSubUrl = await redirectToSubscriptionIfShortLinkFound(to)
  if (shortLinkSubUrl) return { url: shortLinkSubUrl, external: true }

  const personalCabinetUrl = redirectToPersonalCabinetIfPossible(to)
  if (personalCabinetUrl) return { url: personalCabinetUrl, external: true }

  const redirectHomeUrl = redirectToHomeIfPossible(to, params.device)
  if (redirectHomeUrl) return { url: redirectHomeUrl, external: false }

  return null
}

const redirectToPaymentIfPossible = async (currentRoute: RouteLocationNormalized) => {
  const isGA = currentRoute.fullPath.startsWith('/ga/')
  const isG = currentRoute.fullPath.startsWith('/g/')
  const shouldRedirect = isGA || isG
  if (!shouldRedirect) return

  try {
    const { $api } = useNuxtApp()
    const slug = isGA ? currentRoute.fullPath.split('/ga/')[1] : currentRoute.fullPath.split('/g/')[1]

    const { paymentLink } = await $api.paymentRedirects.getPaymentRedirect(slug, isGA)
    if (paymentLink) return paymentLink
  }
  catch (error) {
    return '/'
  }
}

const redirectIfSigningLinkFound = async (currentRoute: RouteLocationNormalized) => {
  const shouldRedirect = currentRoute.fullPath.startsWith('/s/')
  if (!shouldRedirect) return

  const slug = currentRoute.fullPath.split('/s/')[1]
  return `https://lk.pedant.market/s/${slug}`
}

const redirectIfInstallmentSigningLinkFound = async (currentRoute: RouteLocationNormalized) => {
  if (currentRoute.params.modelGroupSlug !== 'i') return
  if (!currentRoute.params.modelSlug && currentRoute.params.modelSlug !== 'string') return

  return `https://lk.pedant.market/i/${currentRoute.params.modelSlug}`
}

const redirectToSubscriptionIfShortLinkFound = async (currentRoute: RouteLocationNormalized) => {
  if (!currentRoute.params.modelGroupSlug || currentRoute.params.modelGroupSlug !== 'sub') return
  const guid = currentRoute.params.modelSlug

  return `/subscription/${guid}?utm_source=bot&utm_campaign=recovery`
}

const redirectIfShortLinkFound = async (currentRoute: RouteLocationNormalized) => {
  const shouldRedirect = currentRoute.fullPath.startsWith('/r/')

  if (!shouldRedirect) return
  try {
    const { $api } = useNuxtApp()
    const slug = currentRoute.fullPath.split('/r/')[1]
    const { To } = await $api.redirects.getRedirect(slug)

    if (To) return To
  }
  catch (error) {
    return '/'
  }
}

const redirectToFileIfPossible = (currentRoute: RouteLocationNormalized) => {
  const routeFileNames = [
    'ofertaPM', 'politics', 'oferta_podpiska', 'oferta_vikup', 'oferta_service',
  ]

  const currentRouteFileName = routeFileNames.find(routeFileName => `/${routeFileName}` === currentRoute.path)

  return currentRouteFileName ? `/static/${currentRouteFileName}.pdf` : false
}

const redirectToPersonalCabinetIfPossible = (currentRoute: RouteLocationNormalized) => {
  if (currentRoute.name !== ROUTE_NAMES.ACCOUNT) return

  return 'https://lk.pedant.market'
}

export const redirectToHomeIfPossible = (currentRoute: RouteLocationNormalized, device?: Device | null) => {
  const isSubscriptionPage = currentRoute.name === ROUTE_NAMES.SUBSCRIPTION
  const isSubscriptionFormPage = currentRoute.name === ROUTE_NAMES.SUBSCRIPTION_FORM
  const isSubscriptionFormFilledPage = currentRoute.name === ROUTE_NAMES.SUBSCRIPTION_FORM_FILLED
  const subscriptionOrderId = currentRoute.params.id

  if ((isSubscriptionPage && !device)
    || (isSubscriptionFormPage && !device)
    || (isSubscriptionFormFilledPage && !subscriptionOrderId && !device)) {
    return '/'
  }

  return
}