import type { RouterConfig } from '@nuxt/schema'
import { ROUTE_NAMES } from '~/constants'

export default {
  scrollBehavior(to, from) {
    // При изменении способа оплаты, изменяется key в адресной строке и осуществляется прокрутка к радио-элементу
    const isChangedKeyForCartPage = to.name === ROUTE_NAMES.CART && from.name === ROUTE_NAMES.CART && to.query?.key
    if (isChangedKeyForCartPage) {
      return {
        el: 'input[name="payment-method-radio-input"]:checked',
        top: 200,
      }
    }

    // always scroll to top
    return { top: 0 }
  },
} satisfies RouterConfig