// Функция для проверки срока истечения clickid в localStorage
const checkClickIdExpiry = () => {
  const clickId = localStorage.getItem('clickid')
  const clickIdExpiry = localStorage.getItem('clickid_expiry')
  if (!clickId || !clickIdExpiry) return

  const now = new Date().valueOf()
  const expiryDate = new Date(clickIdExpiry).valueOf()

  if (now >= expiryDate) {
    // clickid истек, удаляем из localStorage
    localStorage.removeItem('clickid')
    localStorage.removeItem('clickid_expiry')
  }
}

// Функция для установки clickid в localStorage и cookie
const setClickId = () => {
  const paramsArray = window.location.search.substring(1).split('&')
  const paramsResult: Record<string, string> = {}

  if (paramsArray.length) {
    for (let i = 0; i < paramsArray.length; i++) {
      const paramsCurrent = paramsArray[i].split('=')

      paramsResult[paramsCurrent[0]] = paramsCurrent[1]
        ? decodeURIComponent(paramsCurrent[1])
        : ''
    }

    if (paramsResult.clickid) {
      const date = new Date()
      const postClick = 30
      date.setDate(date.getDate() + postClick)
      const expires = date.toISOString()

      localStorage.setItem('clickid', paramsResult.clickid)
      localStorage.setItem('clickid_expiry', expires)
    }
  }
}

export const initMTSClickId = () => {
  // Проверяем и удаляем истекшие clickid
  checkClickIdExpiry()

  // Устанавливаем clickid, если параметры присутствуют
  if (window.location.search.includes('clickid')) {
    setClickId()
  }
}

export const sendMTSClickId = async (orderId: string, sum: number) => {
  const clickId = localStorage.getItem('clickid')
  if (!clickId) return

  return useFetch('https://postback.pn.mts.ru/api/v2/postback/slim', {
    params: {
      ClickId: clickId, // сохраненное значение clickId
      OrderId: orderId, // id заказа подписки из URL страницы
      Sum: sum, // сумма ежемесячной оплаты подписки
      goalId: '3ddc7ea4-cafe-4fd3-9b6e-1864721d0410',
    },
  })
}