<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { onMounted, watch, ref } from 'vue'

const citiesStore = useCitiesStore()
const devicesStore = useDevicesStore()
const serviceCentersStore = useServiceCentersStore()
const modelMemoriesStore = useModelMemoriesStore()
const modelColorsStore = useModelColorsStore()
const salesHitsStore = useSalesHitsStore()

// загружаем список городов
// получаем по ip-адресу город через сервис dadata
// находим текущий город в списке городов по имени
if (!citiesStore.list?.length) {
  await useAsyncData('fetchCities', () =>
    citiesStore.getList().then(() => true),
  )

  if (!citiesStore.hasConfirm) {
    await useAsyncData(
      'fetchCurrentCity',
      () => citiesStore.getCurrent().then(() => true),
    )
  }
}

// загружаем сервисные центры города
if (!serviceCentersStore.list?.length)
  await useAsyncData('fetchServiceCenters', () =>
    serviceCentersStore.getList().then(() => true),
  )

// загружаем варианты памяти моделей
if (!modelMemoriesStore.list?.length)
  await useAsyncData('fetchModelMemories', () =>
    modelMemoriesStore.getList().then(() => true),
  )

// загружаем варианты цветов моделей
if (!modelColorsStore.list?.length)
  await useAsyncData('fetchModelColors', () =>
    modelColorsStore.getList().then(() => true),
  )

// загружаем устройства (хиты продаж)
if (!salesHitsStore.list?.length)
  await useAsyncData('fetchSalesHits', () =>
    salesHitsStore.getList().then(() => true),
  )
</script>