<template>
  <div
    :class="[classes.wrapper, 'sm:hover:shadow-xl', 'cursor-pointer']"
    @click="openDeviceCard(device)"
  >
    <div :class="classes.imageWrapper">
      <div :class="classes.badgesWrapper">
        <div class="flex flex-wrap gap-[5px]">
          <ClientOnly>
            <div
              v-if="isViewed"
              :class="classes.seenTag"
            >
              Просмотрено
            </div>
          </ClientOnly>
        </div>
        <div class="mt-auto">
          <WidgetStoppers :device="device" />
        </div>
      </div>

      <div
        v-if="isLoadingImage"
        :class="[classes.image, skeletonStyles]"
      ></div>
      <div
        v-else
        :class="[classes.image, 'group-hover:scale-110']"
        :style="{ 'background-image': `url(${imageSrc})` }"
      ></div>
    </div>
    <div
      v-if="hasActionButtons"
      :class="classes.icons"
    >
      <WidgetToggleFavouriteDevice :device="device" />
      <WidgetToggleComparableDevice :device="device" />
    </div>
    <div :class="classes.content">
      <div
        v-if="xlAndLarger && monthlyPayment && !device.BasicPrice"
        :class="classes.pricePerMonth"
      >
        Цена сегодня {{ numberSpace(device.Price) }} ₽
      </div>
      <div v-if="monthlyPayment">
        <div :class="classes.subscriptionPrice">
          {{ numberSpace(monthlyPayment) }} <span>₽</span>
          <span :class="classes.subscriptionPriceOld">
            {{ numberSpace(monthlyPaymentOld!) }}
            <span>₽</span>
          </span>
          <span class="block">
            <img
              :src="`/static/images/device-card/${xlAndLarger ? 'cached-desk' : 'cached-mob'}.svg`"
              alt="Повёрнутые стрелки"
              :width="`${xlAndLarger ? 12 : 10}`"
              :height="`${xlAndLarger ? 12 : 10}`"
            >
            12 мес
          </span>
        </div>
        <div
          v-if="!xlAndLarger && !device.BasicPrice"
          :class="classes.pricePerMonth"
        >
          Цена сегодня {{ numberSpace(device.Price) }} ₽
        </div>
      </div>
      <div v-else>
        <div :class="classes.priceTitle">
          Цена сегодня
        </div>
        <div :class="classes.priceRow">
          <div :class="classes.actualPrice">
            {{ numberSpace(device.Price) }} <span>₽</span>
          </div>
        </div>
      </div>
      <nuxt-link
        :to="url"
        :class="[classes.name, stretchedLinkStyles, resetAStyles]"
      >
        <span :class="classes.text">
          {{ device.ModelName + ", " + modelMemoryFancyString + ", " + modelColorFancyString }}
        </span>
      </nuxt-link>
      <div :class="classes.description">
        Состояние: {{ device.ModelCondition.Name }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { ref, computed, onMounted, onUpdated } from 'vue'
import { cx } from 'cva'
import type { Device } from '~/types/models'
import {
  stretchedLinkStyles, resetAStyles, numberSpace, getMonthlyPayment, getMonthlyPaymentOldPrice,
  skeletonStyles, checkIsViewed, loadDeviceImage, getModelColorFancyString,
  getModelMemoryFancyString, emitYandexMetrika, YANDEX_METRIKA_GOALS,
} from '@/utils'
import { ROUTE_NAMES } from '@/constants'

const props = defineProps({
  device: {
    type: Object as PropType<Device>,
    default() { return {} },
    required: true,
  },
  hasActionButtons: {
    type: Boolean,
    default: false,
    required: false,
  },
})

const nameSkeletonClasses = cx([
  'w-full',
  'h-[42px]',
  'xl:h-12',
  'rounded-xl',
  ...skeletonStyles,
])

const classes = {
  wrapper: cx([
    'relative',
    'flex',
    'flex-col',
    'justify-start',
    'w-full',
    'h-full',
    'z-20',
    'rounded-xl',
    'transition',
    'group',
  ]),
  imageWrapper: cx([
    'relative',
    'overflow-hidden',
    'rounded-xl',
  ]),
  image: cx([
    'flex',
    'flex-col',
    'h-[250px]',
    'sm:h-[324px]',
    'bg-cover',
    'bg-no-repeat',
    'bg-center',
    'transition',
    'duration-300',
  ]),
  badgesWrapper: cx([
    'absolute',
    'inset-2.5',
    'z-10',
    'flex',
    'flex-col',
    'justify-between',
    'pointer-events-none',
  ]),
  seenTag: cx([
    'self-start',
    'py-1',
    'px-2',
    'text-[11px]',
    'text-primary-on-color',
    'leading-none',
    'bg-bg-black-transparent',
    'rounded-lg',
    'leading-3',
  ]),
  stoppers: 'mt-auto',
  content: cx([
    'py-2',
    'px-0',
    'xl:pt-3',
    'xl:px-2.5',
    'xl:pb-2',
  ]),
  pricePerMonth: cx([
    'text-[12px]/[14px]',
    'text-primary',
    'mt-1',
    'xl:text-[16px]/[19px]',
    'xl:mt-0',
    'xl:mb-1',
  ]),
  icons: cx([
    'absolute',
    'top-[12px]',
    'right-[12px]',
    'flex',
    'flex-col',
    'z-10',
  ]),
  name: cx([
    'block',
    'font-bold',
    'text-primary',
    'mt-1.5',
    'leading-[14px]',
    'xl:mt-2',
  ]),
  text: cx([
    'block',
    'w-full',
    'overflow-hidden',
    'text-ellipsis',
    'text-primary',
    'text-[12px]/[14px]',
    'font-bold',
    'xl:text-[16px]/[19px]',
  ]),
  description: cx([
    'text-[12px]/[14px]',
    'text-ghost',
    'xl:text-[16px]/[19px]',
    'my-1.5',
  ]),
  descriptionSkeleton: cx([
    nameSkeletonClasses,
    'h-20',
    'mb-0',
    'xl:h-[85px]',
  ]),
  subscriptionPrice: cx([
    'text-[21px]/[21px]',
    'font-bold',
    'text-primary',
    'xl:text-[36px]/[39px]',
    '[&>span]:text-[14px]',
    'xl:[&>span]:text-[16px]/[20px]',
    '[&>span:last-child]:text-[14px]/[18px]',
    '[&>span:last-child]:font-semibold',
    '[&>span:last-child>img]:inline-block',
    '[&>span:last-child>img]:pl-1',
    'xl:[&>span:last-child]:text-[16px]/[20px]',
  ]),
  subscriptionPriceOld: cx([
    'text-[14px]/[12.2px]',
    'xl:text-[20px]/[26px]',
    'font-semibold',
    'line-through',
    'text-ghost',
    'pl-[6px]',
    'xl:pl-[4px]',
  ]),
  subscriptionText: cx([
    'uppercase',
    'bg-icon-ghost',
    'rounded-lg',
    'py-1',
    'px-2',
    'w-min',
    'text-[8px]/[9px]',
    'font-bold',
    'whitespace-nowrap',
    'text-primary-on-color',
    'xl:text-[10px]/[11px]',
    'xl:py-[5px]',
    'xl:px-2',
  ]),
  priceTitle: cx([
    'text-[12px]',
    'font-bold',
    'text-primary',
    'xl:text-[14px]',
    'xl:mb-1.5',
  ]),
  priceRow: cx([
    'flex',
    'items-center',
    'leading-[25px]',
    '-mt-[3px]',
    'mb-[3px]',
  ]),
  actualPrice: cx([
    'text-[21px]',
    'font-bold',
    'text-primary',
    'xl:text-[34px]',
    '[&>span]:text-[16px]/[19px]',
  ]),
}

const viewport = useViewport()
const xlAndLarger = computed(() => viewport.isGreaterOrEquals('xl'))

const router = useRouter()
const userStore = useUserStore()

const isLoadingImage = ref(true)
const imageSrc = ref('')

const modelMemoryFancyString = computed(() => getModelMemoryFancyString(props.device))
const modelColorFancyString = computed(() => getModelColorFancyString(props.device))

const url = computed(() => {
  return {
    name: ROUTE_NAMES.CATALOG_DEVICE,
    params: {
      modelGroupSlug: props.device.ParentSlug,
      modelSlug: props.device.ModelSlug,
      id: props.device.Id,
    },
  }
})

const monthlyPayment = computed(() => getMonthlyPayment(props.device))
const monthlyPaymentOld = computed(() => getMonthlyPaymentOldPrice(props.device))
const isViewed = computed(() => checkIsViewed(props.device))

onMounted(() => loadDeviceImage(props.device, isLoadingImage, imageSrc))
onUpdated(() => loadDeviceImage(props.device, isLoadingImage, imageSrc))

const openDeviceCard = (device: Device) => {
  emitYandexMetrika(YANDEX_METRIKA_GOALS.KARTOCHKA)
  userStore.addViewedDevice(device)
}
</script>