import FetchFactory from '../factory'
import Routes from '../routes.client'
import type {
  DadataFullName,
  DadataIpLocateAddress,
  DadataAddress,
  DadataIssuedBy,
} from '~/types/models'

class DadataModule extends FetchFactory {
  private readonly RESOURCE = Routes.Dadata

  async getAddressByIp(ip?: string) {
    return this.call<DadataIpLocateAddress>({
      method: 'GET',
      url: `${this.RESOURCE.GetAddressByIp(ip)}`,
    })
  }

  async getFullName(text: string) {
    return this.call<DadataFullName>({
      method: 'POST',
      url: `${this.RESOURCE.GetFullName()}`,
      body: {
        query: text,
        parts: ['SURNAME', 'NAME', 'PATRONYMIC'],
      },
    })
  }

  async getAddress(
    text: string,
    fieldKey: string,
    value: {
      region?: string
      city?: string
      street?: string
      house?: string
      flat?: string
    },
  ) {
    const hasAnyValue = Boolean(
      Object.values(value).filter(item => !item).length,
    )

    return this.call<DadataAddress>({
      method: 'POST',
      url: `${this.RESOURCE.GetAddress()}`,
      body: {
        query: text,
        from_bound: { value: fieldKey },
        to_bound: { value: fieldKey },
        ...(hasAnyValue ? { locations: [value] } : {}),
      },
    })
  }

  async getIssuedBy(divisionCode: string) {
    return this.call<DadataIssuedBy>({
      method: 'POST',
      url: `${this.RESOURCE.GetIssuedBy()}`,
      body: {
        query: divisionCode,
      },
    })
  }
}

export default DadataModule