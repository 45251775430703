import FetchFactory from '../factory'
import Routes from '../routes.client'
import type { YandexPayOrderCreationParams, YandexPaySuccessOrderCreation } from '~/types/models'

class YandexPayModule extends FetchFactory {
  private readonly RESOURCE = Routes.YandexPay

  async createOrder(params: YandexPayOrderCreationParams) {
    return this.call<YandexPaySuccessOrderCreation>({
      method: 'POST',
      url: `${this.RESOURCE.createOrder()}`,
      body: params,
    })
  }
}

export default YandexPayModule