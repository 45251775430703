import { $fetch, type FetchOptions } from 'ofetch'
import { defineNuxtPlugin } from '#app'
import CitiesModule from '~/repository/modules/cities.module'
import PromocodesModule from '~/repository/modules/promocodes.module'
import ServiceCentersModule from '~/repository/modules/service-centers.module'
import OrdersModule from '~/repository/modules/orders.module'
import TinkoffModule from '~/repository/modules/tinkoff.module'
import ModelGroupsModule from '~/repository/modules/model-groups.module'
import SalesHitsModule from '~/repository/modules/sales-hits.modules'
import DevicesModule from '~/repository/modules/devices.module'
import ModelMemoriesModule from '~/repository/modules/model-memories.module'
import ModelColorsModule from '~/repository/modules/model-colors.module'
import SeoContentsModule from '~/repository/modules/seo-contents.module'
import OrganizationsModule from '~/repository/modules/organizations.module'
import CustomerPassportsModule from '~/repository/modules/customer-passports.module'
import ModelsModule from '~/repository/modules/models.module'
import PaymentRedirectsModule from '~/repository/modules/payment-redirects.module'
import RedirectsModule from '~/repository/modules/redirects.module'
import ReviewsModule from '~/repository/modules/reviews.module'
import SubscriptionOrdersModule from '~/repository/modules/subscription-orders.module'
import SubscriptionPercentsModule from '~/repository/modules/subscription-percents.module'
import YandexPayModule from '~/repository/modules/yandex-pay.module'

/** ApiInstance interface provides us with good typing */
interface ApiInstance {
  cities: CitiesModule
  customerPassports: CustomerPassportsModule
  devices: DevicesModule
  modelColors: ModelColorsModule
  modelGroups: ModelGroupsModule
  modelMemories: ModelMemoriesModule
  models: ModelsModule
  orders: OrdersModule
  organizations: OrganizationsModule
  paymentRedirects: PaymentRedirectsModule
  promocodes: PromocodesModule
  redirects: RedirectsModule
  reviews: ReviewsModule
  salesHits: SalesHitsModule
  seoContents: SeoContentsModule
  serviceCenters: ServiceCentersModule
  subscriptionOrders: SubscriptionOrdersModule
  subscriptionPercents: SubscriptionPercentsModule
  tinkoff: TinkoffModule
  yandexPay: YandexPayModule
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  const fetchOptions: FetchOptions = {
    baseURL: config.public.apiBaseURL as string,
  }

  /** create a new instance of $fetcher with custom option */
  const apiFetcher = $fetch.create(fetchOptions)

  /** an object containing all repositories we need to expose */
  const modules: ApiInstance = {
    cities: new CitiesModule(apiFetcher),
    customerPassports: new CustomerPassportsModule(apiFetcher),
    devices: new DevicesModule(apiFetcher),
    modelColors: new ModelColorsModule(apiFetcher),
    modelGroups: new ModelGroupsModule(apiFetcher),
    modelMemories: new ModelMemoriesModule(apiFetcher),
    models: new ModelsModule(apiFetcher),
    orders: new OrdersModule(apiFetcher),
    organizations: new OrganizationsModule(apiFetcher),
    paymentRedirects: new PaymentRedirectsModule(apiFetcher),
    promocodes: new PromocodesModule(apiFetcher),
    redirects: new RedirectsModule(apiFetcher),
    reviews: new ReviewsModule(apiFetcher),
    salesHits: new SalesHitsModule(apiFetcher),
    seoContents: new SeoContentsModule(apiFetcher),
    serviceCenters: new ServiceCentersModule(apiFetcher),
    subscriptionOrders: new SubscriptionOrdersModule(apiFetcher),
    subscriptionPercents: new SubscriptionPercentsModule(apiFetcher),
    tinkoff: new TinkoffModule(apiFetcher),
    yandexPay: new YandexPayModule(apiFetcher),
  }

  return {
    provide: {
      api: modules,
    },
  }
})

declare module '#app' {
  interface NuxtApp {
    $api: ApiInstance
  }
}