<template>
  <NuxtLayout>
    <div :class="[classes.wrapper, 'container']">
      <div :class="classes.code">
        404
      </div>
      <div :class="classes.desc">
        Страница не найдена
      </div>
      <BaseButton
        type="nuxt-link"
        intent="base-primary"
        size="middle"
        class="sm:w-auto"
        url="/"
      >
        Перейти на главную
      </BaseButton>
    </div>
    <WidgetSectionPopularModels
      class="mt-0"
      :has-subtitle="false"
    />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { cx } from 'cva'

const classes = {
  wrapper: cx([
    'pt-[50px]',
    'py-2.5',
    'pb-14',
    'my-auto',
    'w-fit',
    'flex',
    'flex-col',
    'items-center',
    'leading-none',
    'xl:pb-6',
  ]),
  code: cx([
    'text-[130px]',
    'font-bold',
    'text-accent',
  ]),
  desc: cx([
    'text-[28px]',
    'text-accent',
    'mb-[30px]',
  ]),
}

useHead({
  title: 'Страница не найдена | Pedant.Market',
  meta: [{ name: 'prerender-status-code', content: 404 }],
})

const citiesStore = useCitiesStore()
const modelGroupsStore = useModelGroupsStore()

if (!modelGroupsStore.list?.length) {
  await useAsyncData('fetchModelGroups', () =>
    modelGroupsStore.getList().then(() => true),
  )
}

// загружаем список городов
// получаем по ip-адресу город через сервис dadata
// находим текущий город в списке городов по имени
if (!citiesStore.list?.length) {
  await useAsyncData('fetchCities', () =>
    citiesStore.getList().then(() => true),
  )

  if (!citiesStore.hasConfirm) {
    await useAsyncData('fetchCurrentCity', () =>
      citiesStore.getCurrent().then(() => true),
    )
  }
}
</script>